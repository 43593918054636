export default function FakeStore() {
  let element = (
    <>
      <span className="titleText">Fake Store</span><br />
      <span className="subTitleText">...coming soon...</span><br/><br/>
      <img  className="projectWidth projectPadding" style={{opacity: '0.65'}} src='https://cdn.alex-souza.com/media/images/dev/fakestore.png' alt="Project Fake Store · React.js · Fake Store website" />
    </>
  );
  return element;
}
